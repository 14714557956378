<template>
  <div class="pro_container">
    <!-- headerbar部分 -->
    <div class="img">
      <img src="../../assets/backImg.jpg" alt="" />
      <div class="title">
        <commonT :value="titleConent" class="commonT" />
      </div>
    </div>
    <!-- 产品内容区部分 -->
    <div class="product_content">
      <commonNav
        :NavList="productCategory"
        :serieId="seriesId"
        class="commonNav"
        @changeserrires="serrires"
      />
     <!-- <transition name='fade' mode='out-in'> -->
      <div class="productList" v-if="DetailsShow" :class="!DetailsShow?'fade':''">
        <div class="cardImg">
        <div class="cardcontent">
          <van-row type="flex">
            <van-col
              :span="20"
              v-for="(item, index) in productList"
              :key="index"
              @click="urlTo(item)"
            >
              <van-card
                :thumb="
                  item.productImg && item.productImg.length > 0
                    ? item.productImg[0].url
                    : ''
                "
                :title="item.productName"
                :desc="item.productIntroduction"
                centered
              >
              </van-card>
            </van-col>
          </van-row>
          </div>
          <van-pagination
            v-model="pageIndex"
            :total-items="total"
            :items-per-page="pageSize"
            @change="changeIndex"
          >
          <template #prev-text>
            <van-icon name="arrow-left" />
          </template>
          <template #next-text>
            <van-icon name="arrow" />
          </template>
          </van-pagination>
        </div>
      </div>
      <!-- </transition> -->
          <!-- 产品详情展示区v-else -->
        <!-- <transition name='fades' mode='out-in'> -->
           <!-- v-if="!DetailsShow" -->
        <div class="productsDetails commonHe popactive"   v-if="!DetailsShow">
         <div class="ItemProductDetail">
            <img :src="ItemContent.productImg&&ItemContent.productImg.length>0?ItemContent.productImg[0].url:''" alt="">
            <div class="introduce">
                <p>产品名称:{{ItemContent.productName}}</p>
                <p>规格:{{ItemContent.productSpecifications}}</p>
            </div>
         </div>
        <van-divider content-position="left" class="divider">详细信息</van-divider>
        <div v-html="editContent" class="rich"></div>
      </div>
       <!-- </transition> -->
    </div>

    <!-- 尾部部分 -->
    <FooterContainer />
  </div>
</template>
<script>
export default {
  name: "M-products",
  data() {
    return {
      aa:false,
      titleConent: {
        name: "产品介绍",
        ename: "Products",
      },
      total: 0,
      pageIndex: 1,
      seriesId: "",
      pageSize: 6,
      productCategory: [],
      currentPage: 1,
      productList: [],
      DetailsShow: true,
      editContent: "",
      ItemContent: {},
    };
  },
  created() {
    // console.log(this.$route.query.id)
    // if(this.$route.query.id){
    //    this.
    // }
    this.getcategroy();
    if (this.$route.query.data) {
      this.seriesId = this.$route.query.seriesId;
      this.urlTo(JSON.parse(this.$route.query.data));
    }
  },
  methods: {
    //获取产品列表数据
    async getproductList() {
      let result = await this.$api.getProductList(
        this.seriesId,
        this.pageSize,
        this.pageIndex
      );
      if (result.code == 0) {
        this.total = Number(result.page.totalCount);
        let res = result.data;
        res.forEach((item) => {
          if (item.productImg) {
            item.productImg = JSON.parse(item.productImg);
          }
        });
        console.log(res);
        this.productList = res;
      }
    },
    //获取产品类别数据
    async getcategroy() {
      console.log(this.DetailsShow);
      let result = await this.$api.getproductCategroy();
      if (result.code == 0) {
        this.productCategory = result.data;
        this.seriesId = result.data[0].id;
        this.getproductList();
      }
    },
    //点击切换分类
    serrires(index) {
      this.DetailsShow = true;
      this.seriesId = index;
      this.pageIndex = 1; //点击切换分类时，重置分页器页码操作
      this.getproductList();
      console.log(index);
    },
    //点击分页切换
    changeIndex(index) {
     console.log(index)
      this.pageIndex = index;
      this.getproductList();
    },
    //点击箭头跳转到对应的产品详情页
    urlTo(d) {
      console.log(d)
      this.ItemContent = d;
      this.DetailsShow = false;
      this.editContent = d.productDetails;
    },
  },
};
</script>
<style lang="scss" scoped>
.pro_container {
  width:100%;
  height: 100%;
  .img {
    width: 100%;
    height: 165px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .title {
      width: 80%;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 64px;
      box-sizing: border-box;
      background-color: #fff;
      &::before {
        position: absolute;
        content: "";
        left: 0;
        bottom: 0;
        height: 1px;
        width: 100%;
        background-color: #ddd;
      }

      .commonT {
        width: 100%;
        padding-left: 20px;
      }
    }
  }
  //内容区
  .product_content {
    // width: 80%;
    // margin: 0 auto;
    width:100%;
    display: flex;
    justify-content: space-between;
    // overflow: hidden;
    box-sizing: border-box;
    .productList {
      width:285px;
      .cardcontent{
          min-height:400px;
      }
    }
  }
}
.van-col {
  margin:0 auto;
  margin-top: 15px;
}
.van-card__title {
  font-size: 14px;
  text-align: left;
  margin-bottom: 5px;
}
.van-pagination {
  margin: 15px auto;
  width:82%;
}
.van-pagination__item {
  height: 20px !important;
}
 .productsDetails {
      flex: 1;
      // width:285px;
      box-sizing: border-box;
      min-height:400px;
      .ItemProductDetail{
         display: flex;
         margin-top:15px;
         img{
           width:100px;
           height:100px;
           display: block;
           padding-left:10px;
         }
         .introduce{
             flex:1;
             p{
               text-align: left;
               white-space: nowrap;
               overflow: hidden;
               text-overflow: ellipsis;
              text-indent: 10px;
             }
         }
      }
      .divider{
        padding:0 15px;
      }
      .rich{
        // padding-left:12px;
        box-sizing: border-box;
      }
       .rich /deep/ p {
        word-break: break-all;  //超出宽度截取
        max-width: 95% !important;
        font-size:15px;
         margin: 0 auto;
      }
      .rich /deep/ img {
        display: block;
        margin: 0 auto;
        max-width: 95% !important;
      }
    }
//  .fade-leave-active {
//   opacity :1;
//   transition: .5s;
// }
// .fade-leave-to{
//   opacity: 0;
// }
// .fade{
//   animation: fade .6s ease-in ;
// }
// @keyframes fade{
//    0%{
//       opacity: 1;
//    }
//   100% {
//     opacity:0;
//   }
// }
// .popactive{
//   animation: fades .5s ease-in ;
// }
// @keyframes fades{
//    0%{
//       opacity: 0;
//    }
//     50% {
//    opacity: 0;
//   }
//   100% {
//     opacity: 1;
//   }
// }
</style>